// Desktop Styles

.App {
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 1;
}

// Mobile Styles

// // At <1200px width, switch to vertical mobile layout
// @media screen and (max-width: 1200px) {
//   .container {
//     position: initial;
//     height: auto;
//     padding-top: max(9vmin, 82.5px);
//     padding-bottom: max(9vmin, 82.5px);
//   }
// }

// // Stop title font scaling with height at height <= 800px and width >= 1200px
// @media screen and (max-height: 800px) and (min-width: 1200px) {
//   .title-wrapper {
//     .title {
//       font-size: 5.25rem;
//     }
//   }
// }

// // Stop title font scaling with height and width at height <= 550px and width <= 1200px
// @media screen and (max-height: 550px) and (max-width: 1200px) {
//   .title-wrapper {
//     .title {
//       font-size: 3.5rem;
//     }
//   }
// }
