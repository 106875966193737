/* Imports */

@import 'animate.css';

/* Colors */

:root {
  // Sailor Color Scheme
  --scrollbar-thumb: #050b1c;
  --scrollbar-bg: #fbeded;
  --navbar-bg: linear-gradient(#050b1c 0%, #081027 100%);
  --mobile-navbar-bg: #050b1c;
  --navbar-logo-stroke: #fbeded;
  --navbar-logo-fill: #fbeded;
  --navbar-text: #ff0001;
  --navbar-icons: #fbeded;
  --navbar-border: #fbededcc;
  --hero-bg: #b40001;
  --hero-logo-stroke: #081027;
  --hero-logo-fill: #fbeded;
  --section-bg-1: #fbeded;
  --section-header-1: #0e2154;
  --section-text-1: #0e2154;
  --section-border-1: #94afea;
  --section-bg-2: #0e2154;
  --section-header-2: #e3ba77;
  --section-text-2: #fbeded;
  --section-border-2: #ff0001;
  --section-bg-3: #b40001;
  --section-header-3: #f0cc91;
  --section-text-3: #fbeded;
  --section-border-3: #0e2154;
  --contact-border: #b40001;
  --contact-text: #0e2154;
  --footer-bg: linear-gradient(#081027 0%, #050b1c 100%);
  --footer-logo-stroke: #fbeded;
  --footer-logo-fill: #fbeded;
  --footer-header: #e3ba77;
  --footer-text: #fbeded;
  --footer-text-2: #94afea;
  --link-hover: #ff0001;
}

/* Fonts */

@font-face {
  font-family: 'Orbitron';
  src: url('./assets/fonts/fonts_v1-1/Orbitron-VariableFont_wght.ttf')
      format(truetype) tech(variations),
    url('./assets/fonts/fonts_v1-1/Orbitron-VariableFont_wght.ttf')
      format('truetype-variations');
  font-weight: 400 900;
  /* 400 - regular, 500 - medium, 600 - semibold, 
     700 - bold, 800 - extra bold, 900 - black */
}

@font-face {
  font-family: 'ZenDots';
  src: url('./assets/fonts/fonts_v1-1/ZenDots-Regular.ttf') format(truetype);
}

@font-face {
  font-family: 'Audiowide';
  src: url('./assets/fonts/fonts_v1-1/Audiowide-Regular.ttf') format(truetype);
}

@font-face {
  font-family: 'Kodchasan';
  src: url('./assets/fonts/fonts_v1-1/Kodchasan-Regular.ttf') format(truetype);
}

@font-face {
  font-family: 'Kodchasan';
  src: url('./assets/fonts/fonts_v1-1/Kodchasan-Italic.ttf') format(truetype);
  font-style: italic;
}

@font-face {
  font-family: 'Kodchasan';
  src: url('./assets/fonts/fonts_v1-1/Kodchasan-ExtraLight.ttf')
    format(truetype);
  font-weight: 200;
}

@font-face {
  font-family: 'Kodchasan';
  src: url('./assets/fonts/fonts_v1-1/Kodchasan-ExtraLightItalic.ttf')
    format(truetype);
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Kodchasan';
  src: url('./assets/fonts/fonts_v1-1/Kodchasan-SemiBold.ttf') format(truetype);
  font-weight: 600;
}

@font-face {
  font-family: 'Kodchasan';
  src: url('./assets/fonts/fonts_v1-1/Kodchasan-SemiBoldItalic.ttf')
    format(truetype);
  font-weight: 600;
  font-style: italic;
}

/* Global Styles */

input,
textarea {
  font-family: 'Orbitron';
  font-weight: 600;
  font-size: 1.8em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Audiowide';
}

p {
  font-size: 1.8rem;
  font-family: 'Orbitron';
  font-weight: 500;
}
