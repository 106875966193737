* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-bg);
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  font-family: 'Orbitron', 'sans-serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

// Variable Font Sizes

@media screen and (min-width: 2500px) and (min-height: 1350px) {
  html {
    font-size: 75%;
  }
}

@media screen and (max-width: 1300px) and (min-width: 801px) {
  html {
    font-size: 50%;
  }
}

@media screen and (max-width: 800px) {
  html {
    font-size: 40%;
  }
}
