.contact-form {
  position: relative;
  width: 100%;
  height: auto;

  .contact-list {
    width: 100%;
    height: auto;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    li {
      position: relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      width: 100%;
      height: 4.5rem;

      &:not(:first-child) {
        margin-top: 2rem;
      }

      &:nth-child(5) {
        height: 15rem;
      }

      &:nth-child(6) {
        height: auto;
      }
    }
  }
}

input[type='text'],
input[type='email'],
input[type='tel'] {
  background-color: var(--section-bg-1);
  color: var(--contact-text);
  height: 100%;
  width: 100%;
  border: 4px solid var(--contact-border);
  padding: 10px;
  outline: none;
}

textarea {
  background-color: var(--section-bg-1);
  color: var(--contact-text);
  width: 100%;
  height: 100%;
  border: 4px solid var(--contact-border);
  resize: none;
  padding: 10px;
  outline: none;
}

input::placeholder,
textarea::placeholder {
  opacity: 0.8;
  color: var(--contact-text);
}

button,
input[type='submit'],
input[type='reset'] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.contact-button-container {
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1%;

  .submit-button {
    position: relative;
    height: 5rem;
    width: 25rem;
    line-height: 5rem;
    text-align: center;
    cursor: pointer;
    font-family: 'Orbitron';
    font-weight: 600;
    font-size: 1.8em;
    letter-spacing: 3px;
    color: var(--section-bg-1);
    transition: color 0.3s ease-in-out;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      opacity: 1;
      background-color: var(--contact-border);
      color: var(--section-bg-1);
      transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    }

    &:hover::before {
      opacity: 0;
      transform: scale(0.5, 0.5);
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      opacity: 0;
      transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
      box-shadow: 0 0 0 3px var(--contact-border);
      color: var(--contact-border);
      transform: scale(1.1, 1.1);
    }

    &:hover::after {
      opacity: 1;
      transform: scale(1, 1);
    }

    &:hover {
      color: var(--contact-border);
    }
  }
}
