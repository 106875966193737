.main-container {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 825px;
  justify-content: center;
  align-items: center;
  background-color: var(--section-bg-1);
  z-index: 0;

  .main-content {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 80%;
    height: 100%;
    align-items: center;

    .logo-container {
      display: flex;
      position: relative;
      justify-content: center;
      align-items: center;
      height: 10%;
      width: 100%;

      .full-logo-static {
        height: 100%;
        width: auto;
        position: absolute;
        stroke: var(--section-bg-2);
        fill: var(--section-bg-2);
        stroke-width: 1;
      }
    }

    .construction-icon-container {
      display: flex;
      position: relative;
      justify-content: center;
      align-items: center;
      height: 8rem;

      .construction-icon {
        color: var(--section-header-1);
        width: auto;
        height: 100%;
      }
    }

    .contact-wrapper {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      position: relative;
      max-width: 500px;
      width: 100%;
      overflow: hidden;
    }
  }
}

.main-header {
  font-size: 4rem;
  text-align: center;
  color: var(--section-header-1);
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.tagline {
  font-family: 'ZenDots';
  color: var(--section-bg-3);
}

.advert-header {
  font-family: 'Orbitron';
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  color: var(--section-bg-3);
}

.rotated-header {
  position: absolute;
  top: 26.5%;
}

.rotated-header-left {
  right: 60%;
}

.rotated-header-right {
  left: 60%;
}

@media screen and (max-width: 1200px) {
  .rotated-header {
    position: relative;
    top: 0;
    margin-top: 2rem;
  }

  .rotated-header-left {
    right: auto;
  }

  .rotated-header-right {
    left: auto;
  }
}
